import {FirebaseApp} from "firebase/app";
import {createContext} from "react";

export interface FirebaseContextData {
  isInitialized: boolean;
  firebase: FirebaseApp | null;
}

export const FirebaseContext = createContext<FirebaseContextData>({
  firebase: null,
  isInitialized: false,
});
