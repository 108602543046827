import * as React from "react";
import {FirebaseContext} from "./firebase-context";
import {FirebaseApp} from "firebase/app";
import {getFirebase} from "./get-firebase-instance";

type ProviderProps = { children: React.ReactNode }

function FirebaseProvider({children}: ProviderProps) {
  const [isInitialized, setIsInitialized] = React.useState(false);
  const [firebase, setFirebase] = React.useState<FirebaseApp | null>(null);
  React.useEffect(() => {
    const firebaseInstance = getFirebase();
    setFirebase(firebaseInstance);
    if (firebaseInstance) {
      setIsInitialized(true);
    }
  }, []);
  return (!isInitialized ? <p>loading</p> : <FirebaseContext.Provider
      value={{
        firebase,
        isInitialized,
      }}>
    {children}
  </FirebaseContext.Provider>);
}

function useFirebase() {
  const context = React.useContext(FirebaseContext);
  if (context === undefined) {
    throw new Error(
        "useFirebase must be used within a FirebaseProvider",
    );
  }
  return context;
}

export {FirebaseProvider, useFirebase};
