export const firebaseConfig = {
  apiKey: process.env.GATSBY_FIREBASE_API_KEY,
  authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
  storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGE_SENDER_ID,
  appId: process.env.GATSBY_FIREBASE_APP_ID,
  measurementId: process.env.GATSBY_FIREBASE_MEASUREMENT_ID,
};

export const bscConfig = {
  chainId: process.env.GATSBY_BSC_CHAINID,
  base: process.env.GATSBY_BSC_URL,
  tx: "/tx/",
  rpcUrls: (process.env.GATSBY_BSC_RPCURLS).split(","),
};

export const apiUrls = {
  base: process.env.GATSBY_FIREBASE_API_URL,
  userOrders: "/user/orders",
  last10Orders: "/orders",
  register: "/register",
};
