// Import the functions you need from the SDKs you need
import {FirebaseApp, getApps, initializeApp} from "firebase/app";
import {connectFirestoreEmulator, getFirestore} from "firebase/firestore";
import {connectFunctionsEmulator, getFunctions} from "firebase/functions";
import {connectAuthEmulator, getAuth} from "firebase/auth";
import {checkIsClient} from "../utils";
import {firebaseConfig} from "./config"
let instance: FirebaseApp | null = getApps().length > 0 ? getApps()[0] : null;

export function getFirebase(): FirebaseApp {
  if (checkIsClient()) {
    if (instance) return instance;
    instance = initializeApp(firebaseConfig);
    const firestoreEmulatorHost = process.env.GATSBY_FIRESTORE_EMULATOR_HOST;
    const firestoreEmulatorPort = process.env.GATSBY_FIRESTORE_EMULATOR_PORT;
    if (firestoreEmulatorHost && firestoreEmulatorPort) {
      const firestore = getFirestore(instance);
      connectFirestoreEmulator(firestore, firestoreEmulatorHost, Number.parseInt(firestoreEmulatorPort));
      console.debug("connected to " + firestoreEmulatorHost);
    }
    const authEmulatorHost = process.env.GATSBY_FIREBASE_AUTH_EMULATOR_HOST;
    if (authEmulatorHost) {
      connectAuthEmulator(getAuth(instance), `http://${authEmulatorHost}`);
      console.debug("connected to " + authEmulatorHost);
    }
    const functionsEmulatorHost = process.env.GATSBY_FIREBASE_FUNCTIONS_EMULATOR_HOST;
    const functionsEmulatorPort = process.env.GATSBY_FIREBASE_FUNCTIONS_EMULATOR_PORT;
    if (functionsEmulatorHost && functionsEmulatorPort) {
      const functions = getFunctions(instance, "europe-west1");
      connectFunctionsEmulator(functions, functionsEmulatorHost, Number.parseInt(functionsEmulatorPort));
      console.debug("connected to " + functionsEmulatorHost);
    }
    return instance;
  }
  return null;
}

